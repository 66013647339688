.offe{
  color: #555;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  text-decoration: line-through;
  text-align: left;
  vertical-align: middle;
  display: inline-block;

}

#card-img {
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 400px;
}
.card-body{
  margin-bottom: -1.5rem;
}


.dsc{
font-size: 18px;
}
.tags{
  font-size: 14px;
}
.offs{
  font-size: 14px;
}
.dlv{
 margin-top: -1rem;
}
@media (max-width: 1399.98px) {  }
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) { 
  #card-img {
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 250px;
  }
}
@media (max-width: 767.98px) { 
  .card-body{
    margin-bottom: -1.5rem;
    margin-top: -1rem;
  }
}
@media (max-width: 575.98px) {
  #card-img {
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 200px;
  }
}
@media (max-width: 400px) {
  .dsc{
      font-size: 10px;
      margin-left: -.5rem;
      margin-right: -.5rem;
    
        }
        .tags{
          font-size: 10px;
        }
        .offs{
          font-size: 10px;
          margin-left: 1rem;
        }
        #card-img {
          width: 100%;
          max-width: 500px;
          height: 100%;
          max-height: 160px;
        }
        .card-body{
          margin-bottom: -1.5rem;
          margin-top: -1.5rem;
        }
}
@media (max-width: 300px) {
  .card-body{
    margin-bottom: -1.5rem;
    margin-top: -1.5rem;
  }
  .dsc{
      font-size: 10px;
      margin-left: -.5rem;
      margin-right: -.5rem;
    
        }
        .tags{
          font-size: 10px;
        }
        .offs{
          font-size: 10px;
          margin-left: 1rem;
        }
        #card-img {
          width: 100%;
          max-width: 500px;
          height: 100%;
          max-height: 100px;
        }
        #card-body{
          margin-top: -1rem;
        }
}
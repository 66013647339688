
.smallImg {
  width: 20%;
  height: 80px;
}
.topHeading {
  font-size: 12px;
  margin-left: 1.2rem;
  margin-bottom: -1rem;
}


#cardi-img {
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 500px;
}


@media (max-width: 1399.98px) { 
  #cardi-img {
    width: 100%;
    max-width: 1500px;
    height: 100%;
    max-height: 500px;
  }
 }
@media (max-width: 1199.98px) {
  #cardi-img {
    width: 100%;
    max-width: 1500px;
    height: 100%;
    max-height: 500px;
  }
}
@media (max-width: 991.98px) { 
  #cardi-img {
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 500px;
  }
}
@media (max-width: 767.98px) {
  #cardi-img {
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 500px;
  }
 }
@media (max-width: 575.98px) {
  #cardi-img {
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 500px;
  }
}
@media (max-width: 400px) {

        #cardi-img {
          width: 100%;
          max-width: 800px;
          height: 100%;
          max-height: 300px;
        }
        #smallImg-resp {
          width: 20%;
          height: 44px;
        }
      
}
@media (max-width: 300px) {

       
        #cardi-img {
          width: 100%;
          max-width: 500px;
          height: 100%;
          max-height: 300px;
        }
        #smallImg-resp {
          width: 20%;
          height: 44px;
        }
       
}
#textalign{
  text-align: left;
}
.ado{
  width: 100%;
}
.mat{
  margin-top:2.5rem; 
}


@media (max-width: 1199.98px) {
  .mat{
    margin-top:3.8rem; 
  }
}
@media (max-width: 575.98px) {
  .mat{
    margin-top:0px; 
  }
}
@media (max-width: 400px) {
  .mat{
    margin-top:0px; 
  }
}
@media (max-width: 300px) {
  .mat{
    margin-top:0px; 
  }
}

.ml{
    margin-left: 2rem;
}

@media screen and (max-width: 767px) {
    .table-responsive {
      overflow-y: scroll;
    }
  }
  